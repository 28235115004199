import { Layouts } from "react-grid-layout";

export const INITIAL_LAYOUTS: Layouts = {
  xl: [
    { w: 1.5, h: 1.5, x: 9, y: 1.5, i: "notifications" },
    { w: 1.5, h: 1.5, x: 10.5, y: 0, i: "date" },
    { w: 1.5, h: 1.5, x: 9, y: 0, i: "message" },
    { w: 1.5, h: 1.5, x: 10.5, y: 1.5, i: "profile" },
    { w: 3, h: 3, x: 3, y: 0, i: "supply" },
    { w: 3, h: 3, x: 0, y: 0, i: "project" },
    { w: 3, h: 3, x: 9, y: 3, i: "finance" },
    { w: 3, h: 3, x: 3, y: 3, i: "resources" },
    { w: 3, h: 3, x: 6, y: 0, i: "wh" },
    { w: 3, h: 3, x: 0, y: 3, i: "sm" },
    { w: 3, h: 3, x: 6, y: 3, i: "rh" },
    { w: 3, h: 3, x: 3, y: 3, i: "dag" },
    { w: 3, h: 3, x: 0, y: 3, i: "invest" },
    { w: 3, h: 3, x: 9, y: 3, i: "st" },
    { w: 3, h: 3, x: 6, y: 3, i: "park" },
    // { w: 3, h: 3, x: 9, y: 2, i: "invest" },
  ],
  lg: [
    { w: 1.5, h: 1.5, x: 9, y: 1.5, i: "notifications" },
    { w: 1.5, h: 1.5, x: 10.5, y: 0, i: "date" },
    { w: 1.5, h: 1.5, x: 9, y: 0, i: "message" },
    { w: 1.5, h: 1.5, x: 10.5, y: 1.5, i: "profile" },
    { w: 3, h: 3, x: 3, y: 0, i: "supply" },
    { w: 3, h: 3, x: 0, y: 0, i: "project" },
    { w: 3, h: 3, x: 9, y: 3, i: "finance" },
    { w: 3, h: 3, x: 3, y: 3, i: "resources" },
    { w: 3, h: 3, x: 6, y: 0, i: "wh" },
    { w: 3, h: 3, x: 0, y: 3, i: "sm" },
    { w: 3, h: 3, x: 6, y: 3, i: "rh" },
    { w: 3, h: 3, x: 3, y: 3, i: "dag" },
    { w: 3, h: 3, x: 0, y: 3, i: "invest" },
    { w: 3, h: 3, x: 9, y: 3, i: "st" },
    { w: 3, h: 3, x: 6, y: 3, i: "park" },
    // { w: 3, h: 7, x: 9, y: 0, i: "k" },
  ],
  md: [
    { w: 3, h: 3, x: 3, y: 0, i: "supply" },
    { w: 3, h: 3, x: 0, y: 0, i: "project" },
    { w: 3, h: 3, x: 9, y: 2, i: "finance" },
    { w: 3, h: 3, x: 3, y: 2, i: "resources" },
    { w: 3, h: 3, x: 6, y: 0, i: "wh" },
    { w: 3, h: 3, x: 0, y: 2, i: "sm" },
    { w: 3, h: 3, x: 6, y: 2, i: "rh" },
    { w: 3, h: 3, x: 3, y: 3, i: "dag" },
    { w: 3, h: 3, x: 0, y: 3, i: "invest" },
    { w: 3, h: 3, x: 9, y: 3, i: "st" },
    { w: 3, h: 3, x: 6, y: 3, i: "park" },
    { w: 1.5, h: 1.5, x: 9, y: 4.5, i: "notifications" },
    { w: 1.5, h: 1.5, x: 10.5, y: 3, i: "date" },
    { w: 1.5, h: 1.5, x: 9, y: 3, i: "message" },
    { w: 1.5, h: 1.5, x: 10.5, y: 4.5, i: "profile" },
  ],
  sm: [
    { w: 3, h: 3, x: 0, y: 0, i: "project" },
    { w: 3, h: 3, x: 3, y: 0, i: "supply" },
    { w: 3, h: 3, x: 6, y: 2, i: "finance" },
    { w: 3, h: 3, x: 3, y: 2, i: "resources" },
    { w: 3, h: 3, x: 9, y: 0, i: "wh" },
    { w: 3, h: 3, x: 0, y: 2, i: "sm" },
    { w: 3, h: 3, x: 6, y: 2, i: "rh" },
    { w: 3, h: 3, x: 3, y: 3, i: "dag" },
    { w: 3, h: 3, x: 0, y: 3, i: "invest" },
    { w: 3, h: 3, x: 9, y: 3, i: "st" },
    { w: 3, h: 3, x: 6, y: 3, i: "park" },
    { w: 1.5, h: 1.5, x: 9, y: 3, i: "message" },
    { w: 1.5, h: 1.5, x: 12, y: 3, i: "date" },
    { w: 1.5, h: 1.5, x: 9, y: 4.5, i: "notifications" },
    { w: 1.5, h: 1.5, x: 10.5, y: 4.5, i: "profile" },
  ],
  xs: [
    { w: 0.5, h: 1.5, x: 0, y: 0, i: "notifications" },
    { w: 0.5, h: 1.5, x: 0.5, y: 0, i: "date" },
    { w: 0.5, h: 1.5, x: 0, y: 1.5, i: "message" },
    { w: 0.5, h: 1.5, x: 0.5, y: 1.5, i: "profile" },
    { w: 1, h: 3, x: 1, y: 0, i: "project" },
    { w: 1, h: 3, x: 0, y: 3, i: "supply" },
    { w: 1, h: 3, x: 1, y: 3, i: "finance" },
    { w: 1, h: 3, x: 0, y: 6, i: "resources" },
    { w: 1, h: 3, x: 1, y: 9, i: "wh" },
    { w: 1, h: 3, x: 0, y: 9, i: "sm" },
    { w: 1, h: 3, x: 1, y: 9, i: "rh" },
    { w: 3, h: 3, x: 3, y: 3, i: "dag" },
    { w: 3, h: 3, x: 0, y: 3, i: "invest" },
    { w: 3, h: 3, x: 9, y: 3, i: "st" },
    { w: 3, h: 3, x: 6, y: 3, i: "park" },
  ],
  xss: [
    { w: 1, h: 1.5, x: 0, y: 0, i: "message" },
    { w: 1, h: 1.5, x: 0.5, y: 0, i: "notifications" },
    { w: 1, h: 1.5, x: 0, y: 0.5, i: "date" },
    { w: 1, h: 1.5, x: 0.5, y: 0.5, i: "profile" },
    { w: 1, h: 3, x: 0, y: 3, i: "project" },
    { w: 1, h: 3, x: 0, y: 6, i: "supply" },
    { w: 1, h: 3, x: 0, y: 9, i: "finance" },
    { w: 1, h: 3, x: 0, y: 12, i: "resources" },
    { w: 1, h: 3, x: 0, y: 15, i: "wh" },
    { w: 1, h: 3, x: 0, y: 18, i: "sm" },
    { w: 1, h: 3, x: 0, y: 21, i: "rh" },
    { w: 3, h: 3, x: 3, y: 3, i: "dag" },
    { w: 3, h: 3, x: 0, y: 3, i: "invest" },
    { w: 3, h: 3, x: 9, y: 3, i: "st" },
    { w: 3, h: 3, x: 6, y: 3, i: "park" },
  ],
};
