import React, { useEffect, useState } from "react";
import { HashRouter as Router, Link, Redirect, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";

import { AuthContext } from "./context/auth";
import Login from "./pages/Login";
import Applauncher from "./pages/AppLauncher";

function parseJwt(token) {
  var base64Url = token.split(".")[1];
  var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  var jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

function App(props) {
  //@auth.userdata
  const existingTokens =
    localStorage.getItem("@auth.userdata") !== undefined
      ? localStorage.getItem("@auth.userdata")
      : "{}";

  const [authTokens, setAuthTokens] = useState(JSON.parse(existingTokens));

  if (authTokens?.token) {
    var token = parseJwt(authTokens?.token);
    authTokens.valid = token.exp >= Math.floor(Date.now() / 1000);
    console.log(token.exp, Math.floor(Date.now() / 1000), authTokens.valid);
  }

  const setTokens = (data) => {
    localStorage.setItem("@auth.userdata", JSON.stringify(data));
    localStorage.setItem("@auth.token", JSON.stringify(data?.token));
    setAuthTokens(data);
  };

  useEffect(() => {
    //setAuthTokens(JSON.parse(window.localStorage.getItem("@auth.userdata") || "{}"));
  }, []);
  return (
    <AuthContext.Provider value={{ authTokens, setAuthTokens: setTokens }}>
      <Router>
        <Route exact path="/">
          <Redirect to="/home" />
        </Route>
        <Route path="/login" component={Login} />
        {/* <Route path="/signup" component={Signup} /> */}
        <PrivateRoute path="/home" component={Applauncher} />
      </Router>
    </AuthContext.Provider>
  );
}

export default App;
